<div mat-dialog-title>Webhook History</div>
<mat-dialog-content>
<div>
    Webhook Id: {{webhookEvent?.eventId}}
        <table  aria-label="webhook events"  mat-table matSort [dataSource]="dataSource" (matSortChange)="announceSortChange($event)">
            <ng-container matColumnDef="tryNo">
              <th mat-header-cell *matHeaderCellDef mat-sort-header> Attempt No. </th>
              <td mat-cell *matCellDef="let element"> {{element.tryNo}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="tryDate">
              <th mat-header-cell *matHeaderCellDef> Attempt date </th>
              <td mat-cell *matCellDef="let element"> {{element?.tryDate}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="subtasks">
              <th mat-header-cell *matHeaderCellDef> Attempt steps </th>
              <td mat-cell *matCellDef="let element"> 
                <ul class="list-unstyled list p-2 mb-0">
                    <li *ngFor="let subtask of element?.subtasks" class="mb-3 d-flex align-items-center"><i class="fa mr-3" [ngClass]="{
                        'fa-times-circle' : subtask?.stepStatus === 'failed',
                        'fa-check-circle': subtask?.stepStatus === 'success',
                        'fa-stop-circle': subtask?.stepStatus === 'skipped'
                    }"></i> {{subtask?.stepName}}</li>
                 </ul>
                </td>
            </ng-container>

            <ng-container matColumnDef="response">
                <th mat-header-cell *matHeaderCellDef> Attempt response </th>
                <td mat-cell *matCellDef="let element"> {{element?.errorDetails}} </td>
              </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
</div>
</mat-dialog-content>
<div mat-dialog-actions>
    <button class="btn btn-primary" mat-button (click)="onNoClick()">Close</button>
</div>